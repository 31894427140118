var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-grid"},[_c('div',{staticClass:"model-title",style:(_vm.theme
        ? {
            background: _vm.theme.tabBg,
            color: _vm.theme.tabTextColor
          }
        : {})},[_c('i',{staticClass:"dot",style:(_vm.theme
          ? {
              backgroundColor: _vm.theme.tabDotBg
            }
          : {})}),_c('span',{staticClass:"title"},[_vm._v("常用导航")])]),_c('div',{staticClass:"grid"},_vm._l((_vm.list),function(item,index){return _c('a',{key:index,staticClass:"item",attrs:{"title":item.link,"href":item.link}},[_c('img',{staticClass:"icon",attrs:{"src":item.icon}}),_c('span',{staticClass:"txt"},[_vm._v(_vm._s(item.name))])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }