<template>
  <div :class="['model-tab-container', alias + '-model-tab-container']">
    <div class="nav-line">
      <div ref="nav" :class="[showIcon ? 'iconPadding' : '', isShowBtn ? 'hasMore' : '']" class="model-nav">
        <div v-if="showIcon" class="icon-btn left-btn" @click="setNavOffset(-1)">
          <i v-if="contentWidth > navWidth" class="iconfont icon-wangqian"></i>
        </div>
        <div ref="content" class="nav-content">
          <span
            v-for="(item, index) in list"
            :key="item.id"
            :class="[index == active ? 'active' : '', list.length > 1 ? 'line' : '']"
            @click="onClickItem(index)"
            >{{ item.name }}</span
          >
        </div>
        <div v-if="showIcon" class="icon-btn right-btn" @click="setNavOffset(1)">
          <i v-if="contentWidth > navWidth" class="iconfont icon-wanghou"></i>
        </div>
      </div>
      <div class="more" @click="handleMore" v-if="isShowBtn && $route.name !== 'wikiSecondsNav'">
        <span>{{ isShowMore ? "更多" : "收起" }}</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="model-tab-content">
      <home-item-wrapper
        v-for="(item, index) in list"
        v-show="active == index"
        :key="index"
        :isShowMore="isShowMore && $route.name !== 'wikiSecondsNav'"
        :is-max-icon="item.is_max_icon || 1"
        :pItem="item"
      />
    </div>
  </div>
</template>

<script>
import HomeItemWrapper from "../../components/homeItemWrapper";

const iconWidthNumber = {
  2: 11,
  6: 8,
  7: 8,
  1: 5,
  3: 6,
  4: 5,
  5: 5
};

export default {
  name: "modelTab",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  model: {},
  components: { HomeItemWrapper },
  data() {
    return {
      active: 0,
      contentWidth: 0,
      navWidth: 868,
      showIcon: false,
      isShowMore: false
    };
  },
  computed: {
    isShowBtn() {
      const current = this.list[this.active];
      if (current) {
        const child = current.child;
        const grad = current.grad;
        if (!grad) {
          return false;
        }
        const curNumber = iconWidthNumber[current.is_max_icon || 1];
        const number = grad * curNumber;
        if (child && child.length > number) {
          // eslint-disable-next-line
          this.isShowMore = true;
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    handleMore() {
      this.isShowMore = !this.isShowMore;
    },
    setContentWidth() {
      let width = 0;
      this.$refs.content.querySelectorAll("span").forEach(item => {
        width += item.clientWidth;
        // 加上margin的宽度；
        width += 40;
      });
      this.contentWidth = width;
      if (this.contentWidth > this.navWidth && this.device.desktop) {
        this.showIcon = true;
      }
    },
    setNavOffset(type) {
      let left = this.$refs.content.scrollLeft + type * this.navWidth * 0.8;
      this.scrollTo(left);
    },
    onClickItem(index) {
      this.active = index;
      let $spans = this.$refs.content.querySelectorAll("span");
      // 设置选中项居中
      let left = $spans[index].offsetLeft - (this.navWidth - $spans[index].clientWidth) / 2;
      this.scrollTo(left);
    },
    scrollTo(left) {
      this.$refs.content.scrollTo({
        left: left,
        top: 0,
        behavior: "smooth"
      });
    }
  },
  watch: {},
  created() {},
  async mounted() {
    await this.$nextTick();
    this.setContentWidth();
    this.navWidth = this.$refs.nav.clientWidth;
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.model-tab-container {
  padding: 0 3px;

  .nav-line {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    .more {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      height: 20px;
      margin-left: 5px;
      padding: 2px 8px;
      color: #999;
      font-size: 12px;
      border-radius: 24px;
      background-color: #f4f4f4;
      cursor: pointer;
    }
  }

  .model-nav {
    flex: 1;
    width: 100%;
    margin-bottom: 8px;
    line-height: 30px;
    white-space: nowrap;
    position: relative;
    user-select: none;
    border-bottom: 1px solid @border-basic;

    &.hasMore {
      width: calc(100% - 60px);
    }
    &.iconPadding {
      padding: 0 30px;
    }

    &.wap-nav {
      .nav-content {
        .scrollbar();

        span {
          margin-right: 30px;
        }
      }
    }

    &.pc-nav {
      .nav-content {
        overflow: hidden;
      }
    }

    .icon-btn {
      width: 24px;
      height: 100%;
      font-size: 18px;
      position: absolute;
      top: 0;
      z-index: 10;
      cursor: pointer;
      background: #fff;
      color: @text-gray;

      &.left-btn {
        left: 0;
      }

      &.right-btn {
        right: 0;
        text-align: right;
      }
    }

    .nav-content {
      width: 100%;
      white-space: nowrap;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    span {
      margin-right: 20px;
      color: @text-gray;
      transition: 0.3s;
      cursor: pointer;
      font-size: 14px;
      display: inline-block;
      position: relative;
      min-width: 30px;
      text-align: center;

      &:hover {
        color: fade(@text-basic, 50%);
      }

      &.active {
        color: #a77334;

        &.line:after {
          content: "";
          background: #be8e54;
          width: 20px;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
    }
  }
}

.ba-model-tab-container {
  .model-nav {
    border-bottom: none;
    margin-bottom: 8px;

    span {
      width: auto;
      height: 26px;
      padding: 0 16px;
      line-height: 26px;
      font-size: 12px;
      margin-right: 14px;
      background: url("~@/assets/ba_imgs/item-nav-bg.png") no-repeat center / 100% 100%;

      &.active {
        background: url("~@/assets/ba_imgs/item-nav-bg-active.png") no-repeat center / 100% 100%;
        color: #692b2b;

        &.line:after {
          content: none;
        }
      }
    }
  }
}

.zsca2-model-tab-container {
  // padding: 0 3px;
  .nav-line {
    .more {
      color: var(--zs-model-tab-more-text-color);
      background: var(--zs-model-tab-more-bg);
    }
  }
  .model-nav {
    border: none;

    span {
      position: relative;
      display: inline-block;
      min-width: 30px;
      text-align: center;
      transition: 0.3s;
      cursor: pointer;
      font-size: 12px;
      color: var(--zs-model-tab-title-text-color);
      line-height: 16px;
      margin-right: 30px;

      &:hover,
      &.active {
        font-weight: bold;
        font-size: 12px;
        color: var(--zs-model-tab-title-active-color);
        line-height: 16px;
        &.line:after {
          content: none;
        }
      }
    }
  }
}
</style>
