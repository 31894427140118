<template>
  <div class="aside-bar">
    <!-- 公告 -->
    <el-popover trigger="hover" placement="right-start" popper-class="popover-box" width="274" :visible-arrow="false">
      <div
        class="container"
        :style="
          theme
            ? {
                background: theme.asidePopBg
              }
            : {}
        "
      >
        <div class="title">公告</div>
        <div class="content">
          <div class="gg-content" v-html="gameInfo.description"></div>
        </div>
      </div>
      <div class="item" slot="reference">
        <!-- <img class="icon" src="@/assets/zs_imgs/wiki-icon-gg.png" alt="" /> -->
        <i class="icon zsicon icon-gonggao" alt=""></i>
        <span class="label">公告</span>
      </div>
    </el-popover>
    <!-- 友链 -->
    <el-popover trigger="hover" placement="right-start" popper-class="popover-box" width="274" :visible-arrow="false">
      <div
        class="container"
        :style="
          theme
            ? {
                background: theme.asidePopBg
              }
            : {}
        "
      >
        <div class="title">友情链接</div>
        <div class="content">
          <div class="friend-links">
            <a class="link-item" v-for="(item, index) in friendLinkList" :key="index" :href="item.link" target="_blank">
              <template v-if="item.icon">
                <img v-lazy="handleImgSize(item.icon, 'small')" alt="" />
              </template>
              <span>{{ item.desc }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="item" slot="reference">
        <!-- <img class="icon" src="@/assets/zs_imgs/wiki-icon-yl.png" alt="" /> -->
        <i class="icon zsicon icon-QQ" alt=""></i>
        <span class="label">友链</span>
      </div>
    </el-popover>
    <!-- 管理 -->
    <el-popover trigger="hover" placement="right-start" popper-class="popover-box" width="274" :visible-arrow="false">
      <div
        class="container"
        :style="
          theme
            ? {
                background: theme.asidePopBg
              }
            : {}
        "
      >
        <div class="title">管理员</div>
        <div class="content">
          <div class="manage-user-list">
            <router-link
              :to="`/user/index/${item.uid}.html`"
              class="user-item"
              v-for="(item, index) in adminList.slice(0, 6)"
              :key="index"
            >
              <img class="avatar" :src="item.avatar" alt="" />
              <span class="username">{{ item.username }}</span>
            </router-link>
          </div>
          <div
            class="apply-admin"
            @click="openApply"
            :style="
              theme
                ? {
                    background: theme.primaryBtnBg,
                    color: theme.primaryBtnColor
                  }
                : {}
            "
          >
            申请成为管理员
          </div>
          <!-- 申请wiki管理员 -->
          <apply-wiki-admin ref="applyWikiAdmin"></apply-wiki-admin>
        </div>
      </div>
      <div class="item" slot="reference">
        <i class="icon zsicon icon-guanli" alt=""></i>
        <span class="label">管理</span>
      </div>
    </el-popover>
    <!-- 分享 -->
    <div class="item" @click="share">
      <i class="icon zsicon icon-fenxiang2" style="font-size: 20px;" alt=""></i>
      <span class="label">分享</span>
    </div>
    <!-- 发帖 -->
    <div class="item" @click="goEdit">
      <i class="icon zsicon icon-bianji1" style="font-size: 20px;" alt=""></i>
      <span class="label">发帖</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Clipboard from "clipboard";
// apis
import { apiWikiAdminList } from "@/api/wikiApi";
// components
import ApplyWikiAdmin from "@/views/wiki/components/applyWikiAdmin";

export default {
  props: {
    modelsList: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ApplyWikiAdmin
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["gameInfo", "loginUser", "adminList"]),
    friendLinkList() {
      return this.modelsList[2].list || [];
    }
  },
  beforeMount() {
    this.getAdminList();
  },
  methods: {
    getAdminList() {
      apiWikiAdminList().then(({ data }) => {
        this.$store.commit("set_adminList", data || []);
      });
    },
    // 打开管理员申请
    openApply() {
      this.$refs.applyWikiAdmin.visible = true;
    },
    // 分享
    share() {
      let shareText = window.location.href;
      let $div = document.createElement("div");
      $div.setAttribute("data-clipboard-text", shareText);
      $div.classList.add("page-share-copy-btn");
      document.body.appendChild($div);
      let clipboard = new Clipboard(".page-share-copy-btn");
      clipboard.on("success", e => {
        this.$message("复制成功！");
        document.body.removeChild($div);
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$message("浏览器不支持自动复制,请更换其他浏览器");
        document.body.removeChild($div);
        clipboard.destroy();
      });
      $div.click();
    },
    // 去发帖
    goEdit() {
      if (!this.isLoginShow("")) {
        sessionStorage.setItem("login_jump_path", `/${this.gameInfo.alias}/editor`);
        return false;
      }
      if (!this.isAuthRealName()) {
        sessionStorage.setItem("login_jump_path", `/${this.gameInfo.alias}/editor`);
        return false;
      }
      this.$router.push(`/${this.gameInfo.alias}/editor`);
    }
  }
};
</script>

<style lang="less">
.popover-box {
  padding: 0;
  border-radius: 15px;
  background: transparent;
  border: none;
  .container {
    position: relative;
    width: 100%;
    min-height: 120px;
    border-radius: 15px;
    background: var(--zs-aside-bar-pop-bg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 19px 11px;
    border-radius: 16px;
    // &::before {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 1;
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 50px;
    //   overflow: hidden;
    //   // background: var(--zs-aside-bar-pop-bg) no-repeat;
    //   background-size: 100%;
    //   border-radius: 16px 16px 0 0;
    // }

    // &::after {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 1;
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 50px;
    //   overflow: hidden;
    //   // background: var(--zs-aside-bar-pop-bg) no-repeat;
    //   background-size: 100%;
    //   background-position: 0 -178px;
    //   border-radius: 0 0 16px 16px;
    // }
    .title {
      position: relative;
      z-index: 2;
      font-weight: 800;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      text-align: center;
      background: url("~@/assets/zs_imgs/popover-box-title-bg.png") no-repeat;
      background-size: contain;
      background-position: center center;
      margin-bottom: 22px;
    }
    .content {
      position: relative;
      z-index: 2;
      padding: 12px;
      border-radius: 8px;
      background: var(--zs-aside-bar-pop-content-bg);

      .gg-content {
        font-weight: 400;
        font-size: 12px;
        color: var(--zs-aside-bar-pop-content-color);
        line-height: 18px;
      }

      .friend-links {
        display: flex;
        flex-wrap: wrap;
        // padding-left: 8px;
        gap: 8px 8px;
        .link-item {
          width: 110px;
          height: 24px;
          background: var(--zs-aside-bar-pop-friend-bg);
          border-radius: 2px;
          font-weight: 400;
          font-size: 12px;
          color: var(--zs-aside-bar-pop-friend-color);
          line-height: 24px;
          padding: 0 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &:hover {
            opacity: 0.9;
          }
          img {
            width: 15px;
            height: 15px;
            margin-right: 8px;
            vertical-align: middle;
          }
        }
      }

      .manage-user-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 18px 16px;
        .user-item {
          width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 6px;
          .avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          .username {
            width: 100%;
            display: block;
            font-weight: 400;
            font-size: 12px;
            color: var(--zs-aside-bar-pop-friend-color);
            line-height: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
          }
        }
      }

      .apply-admin {
        width: 216px;
        height: 32px;
        background: var(--zs-aside-bar-pop-apply-admin-bg);
        border-radius: 16px;
        font-size: 14px;
        color: var(--zs-aside-bar-pop-apply-admin-text-color);
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        margin: 34px auto 10px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.aside-bar {
  position: fixed;
  top: 72px;
  left: 50%;
  margin-left: 615px;
  width: 52px;
  .item {
    width: 52px;
    height: 52px;
    color: var(--zs-aside-bar-text-color);
    background: var(--zs-aside-bar-bg);
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 14px;
    &:hover {
      opacity: 0.9;
    }
    .icon {
      font-size: 24px;
    }
    .label {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
    }
  }
}
</style>
