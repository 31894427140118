<template>
  <div :id="`menu-${item.id}`" class="model-container">
    <div
      class="model-title"
      :style="
        theme
          ? {
              background: theme.tabBg,
              color: theme.tabTextColor
            }
          : {}
      "
    >
      <i
        class="dot"
        :style="
          theme
            ? {
                backgroundColor: theme.tabDotBg
              }
            : {}
        "
      ></i>
      <span class="title">{{ item.name }}</span>
    </div>
    <template>
      <model-tab v-for="(child, index) in list" :key="index" :list="child"/>
    </template>
  </div>
</template>

<script>
import ModelTab from "./modelTab.vue";
import { mapGetters } from "vuex";

export default {
  name: "homeModelContainer",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isShowMore: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: { ModelTab },
  data() {
    return {
      list: []
    };
  },
  computed: {
    ...mapGetters(["gameInfo"])
  },
  methods: {
    bgStyle(filed, type = "cover") {
      let config = this.gameInfo.Config || {};
      if (config[filed]) {
        return {
          background: `url(${config[filed]})no-repeat left center`,
          backgroundSize: type
        };
      } else {
        return "";
      }
    },
    handleItem() {
      let { item } = this;
      this.list = [];
      let tab_num = -1;
      let arr = [];
      item.child = item.child ? item.child : [];
      item.child.forEach(child => {
        if (child.tab_num == tab_num) {
          arr.push(child);
        } else {
          if (arr.length) {
            this.list.push(arr);
            arr = [];
          }
          tab_num = child.tab_num;
          arr.push(child);
        }
      });
      if (arr.length > 0) {
        this.list.push(arr);
      }
    }
  },
  watch: {
    item: "handleItem"
  },
  created() {
    this.handleItem();
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.model-container {
  margin-bottom: 14px;
  .model-title {
    display: flex;
    align-items: center;
    height: 34px;
    font-weight: bold;
    font-size: 16px;
    color: var(--zs-server-tab-color);
    line-height: 34px;
    background: var(--zs-server-wrapper-bg);
    margin-bottom: 10px;
    border-radius: 4px;
    .dot {
      display: block;
      width: 3px;
      height: 16px;
      background: var(--zs-model-title-before);
      margin: 0 7px 0 9px;
    }
  }
}
</style>
