var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"server-select"},[_c('div',{staticClass:"wrapper",style:(_vm.theme
        ? {
            backgroundColor: _vm.theme.tabBg,
            color: _vm.theme.tabTextColor
          }
        : {})},[_c('div',{staticClass:"left"},[_c('span',{staticClass:"dot"}),_c('div',{staticClass:"now-date"},[_vm._v(_vm._s(_vm.dayjs().format("YYYY年MM月DD日"))+" "+_vm._s(_vm.getWeek()))])]),(_vm.list.length && _vm.list.length > 0)?_c('div',{staticClass:"right"},[_c('el-dropdown',{attrs:{"trigger":"click","placement":"bottom-start"},on:{"command":_vm.changeServer}},[_c('div',{staticClass:"server-select-box",style:(_vm.theme
              ? {
                  backgroundColor: _vm.theme.tabSelectBg,
                  color: _vm.theme.tabSelectTextColor
                }
              : {})},[_c('span',[_vm._v(_vm._s(_vm.showName)+" ")]),_c('i',{staticClass:"icon el-icon-caret-right",class:[_vm.list.length <= 1 ? 'disabled-icon' : '']})]),_c('el-dropdown-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.list.length > 1),expression:"list.length > 1"}],staticClass:"server-select-menu",attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.list),function(item){return _c('el-dropdown-item',{key:item.id,style:(_vm.theme
                ? {
                    backgroundColor: ((_vm.theme.tabSelectBg) + " !important"),
                    color: _vm.theme.tabSelectTextColor
                  }
                : {}),attrs:{"command":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }