<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="400px"
    append-to-body
    custom-class="shenqing-dialog"
  >
    <div slot="title">
      <div class="game-info">
        <img :src="gameInfo.icon" alt="" />
        <span>{{ gameInfo.name }}</span>
      </div>
    </div>
    <div class="title">管理员申请</div>
    <div class="input-box">
      <div class="text"><span>*</span>关于成为wiki管理员，有什么想说的？</div>
      <textarea v-model="sqDesc" maxlength="200" placeholder="请输入联系方式及申请理由"></textarea>
    </div>
    <el-button class="ok-btn" size="default" type="primary" @click="onOkSq">提交</el-button>
  </el-dialog>
</template>

<script>
// api
import { apiAdminApply } from "@/api/userApi";
import { mapGetters } from "vuex";

export default {
  name: "ApplyWikiAdmin",
  data() {
    return {
      visible: false,
      sqDesc: ""
    };
  },
  computed: {
    ...mapGetters(["gameInfo"])
  },
  methods: {
    onOkSq() {
      let params = {
        desc: this.sqDesc
      };
      if (!params.desc) {
        this.$message("请输入联系方式与申请理由！");
        return;
      }
      apiAdminApply(params).then(res => {
        this.sqDesc = "";
        this.visibleSq = false;
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .shenqing-dialog {
  overflow: hidden;

  .el-dialog__header {
    padding: 0;
    background: @text-basic;

    .game-info {
      height: 60px;
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      padding-left: 10px;

      img {
        width: 36px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid #fff;
        margin-right: 10px;
      }
    }

    .el-dialog__close {
      color: #fff;
    }
  }

  .title {
    color: #171717;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .input-box {
    margin-bottom: 30px;

    .text {
      color: #171717;
      font-size: 14px;

      span {
        color: @red;
      }
    }

    textarea {
      border: 1px solid @border-basic;
      border-radius: 4px;
      resize: none;
      height: 120px;
      padding: 10px;
      width: 100%;
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .ok-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
