/**
 * 解析wiki entrylist
 * @param {*} list
 * @returns
 */
export function handleModelList(list) {
  let modelsList = {
    1: {
      list: [],
      module: {}
    },
    2: {
      list: [],
      module: {}
    },
    3: {
      list: [],
      module: {}
    },
    4: {
      list: [],
      module: {}
    },
    5: {
      list: [],
      module: {}
    },
    6: {
      list: [],
      module: {}
    },
    7: {
      list: [],
      module: {}
    },
    8: {
      list: [],
      module: {}
    },
    9: {
      list: [],
      module: {}
    },
    19: {
      list: [],
      module: {}
    },
    20: {
      list: [],
      module: {}
    },
    21: {
      list: {},
      module: {}
    }
  };
  // console.log(list);
  if (list) {
    list.forEach(item => {
      if (item.module.type == 6) {
        item.list &&
          Array.isArray(item.list) &&
          item.list.forEach(item => {
            item.tag = item.tag || "0";
          });
      }
      modelsList[item.module.type] = item;
    });
  }
  return modelsList;
}
