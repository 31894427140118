var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qz-list",style:({ maxHeight: _vm.homeLeftHeight + 'px' })},[_c('div',{staticClass:"title",style:(_vm.theme
        ? {
            backgroundColor: _vm.theme.tabBg,
            color: _vm.theme.tabTextColor
          }
        : {})},[_c('div',{staticClass:"left"},[_c('i',{staticClass:"dot",style:(_vm.theme
            ? {
                backgroundColor: _vm.theme.tabDotBg
              }
            : {})}),_vm._v(" "+_vm._s(_vm.gameInfo && _vm.gameInfo.Config && _vm.gameInfo.Config.sq_alias ? _vm.gameInfo.Config.sq_alias : "圈子")+" ")]),_c('div',{staticClass:"right",on:{"click":_vm.openMore}},[_vm._v("更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn",style:(_vm.theme
          ? {
              backgroundColor: _vm.theme.primaryBtnBg,
              color: _vm.theme.primaryBtnColor
            }
          : {}),on:{"click":_vm.goEdit}},[_c('i',{staticClass:"icon zsicon icon-bianji",attrs:{"alt":""}}),_c('span',[_vm._v("投稿/发帖")])]),_c('button',{staticClass:"btn",style:(_vm.theme
          ? {
              backgroundColor: _vm.theme.primaryBtnBg,
              color: _vm.theme.primaryBtnColor
            }
          : {}),on:{"click":_vm.openQuestion}},[_c('i',{staticClass:"icon zsicon icon-tiwen",attrs:{"alt":""}}),_c('span',[_vm._v("萌新提问")])])]),_c('div',{staticClass:"list-nav"},[_vm._l((_vm.orderObj),function(item,index){return _c('div',{key:index,staticClass:"list-nav-item",class:{ active: _vm.activeOrder === item.value },style:(_vm.theme && _vm.activeOrder === item.value
          ? {
              color: _vm.theme.activeTextColor
            }
          : {}),on:{"click":function (e) { return _vm.handleOrder(e, item.value); }}},[_vm._v(" "+_vm._s(item.label)+" ")])}),_c('div',{staticClass:"active-bar",style:(_vm.theme
          ? { left: _vm.activeBarOffset + 'px', backgroundColor: _vm.theme.activeTextColor }
          : { left: _vm.activeBarOffset + 'px' })})],2),_c('div',{staticClass:"list",style:({ maxHeight: _vm.homeLeftHeight - 220 + 'px', overflow: 'hidden' })},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"18px"}},[_c('router-link',{staticClass:"list-item",attrs:{"to":_vm.getJumpWikiDetailUrl(item.game.alias, item.game.id, item.id, true)}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[(item.is_top)?_c('span',{staticClass:"is-top"},[_vm._v("[置顶]")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"right"},[(item.image_num || item.thumb)?_c('img',{staticClass:"icon has-img",attrs:{"src":require("@/assets/zs_imgs/has-img-icon.png"),"alt":""}}):_vm._e(),(item.video_list_format && item.video_list_format.length)?_c('img',{staticClass:"icon has-video",attrs:{"src":require("@/assets/zs_imgs/has-video-icon.png"),"alt":""}}):_vm._e()])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"user"},[_c('img',{staticClass:"avatar",attrs:{"src":item.user.avatar,"alt":""}}),_c('span',{staticClass:"username"},[_vm._v(_vm._s(item.user.username))])]),_c('div',{staticClass:"data"},[_c('div',{staticClass:"data-item comment-data"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/zs_imgs/wiki-list-comment-icon.png"),"alt":""}}),_vm._v(" "+_vm._s(item.comment_count)+" ")]),_c('div',{staticClass:"data-item like-data",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onLike(item, index)}}},[_c('img',{staticClass:"icon",attrs:{"src":item.is_like
                    ? require('@/assets/zs_imgs/wiki-list-like-active-icon.png')
                    : require('@/assets/zs_imgs/wiki-list-like-icon.png'),"alt":""}}),_vm._v(" "+_vm._s(item.like_count)+" ")])])])]),(index == 1)?_c('div',{staticClass:"quyunba-box"},[_vm._m(0,true)]):_vm._e(),(index == 3)?_c('EntryUpdate',{attrs:{"list":_vm.lastUpdateList}}):_vm._e(),(_vm.alias === 'zsca2' && index === 5)?_c('div',{staticClass:"battle-banner",on:{"click":function($event){return _vm.goEdit('battle')}}}):_vm._e(),(_vm.alias === 'zsca2' && _vm.serverId && index === 7)?_c('div',{staticClass:"ck-banner",on:{"click":_vm.goCk}}):_vm._e(),(index === 9 && _vm.cdkList && _vm.cdkList.length)?_c('CdkBanner',{attrs:{"list":_vm.cdkList,"serverId":_vm.serverId,"serverList":_vm.serverList}}):_vm._e()],1)}),0),_c('div',{ref:"moreBtn",staticClass:"more",on:{"click":_vm.openMore}},[_vm._v(" 查看更多 ")]),_c('QuestionAdd',{ref:"questionAdd",attrs:{"customClass":_vm.questionAddCustomClass}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.quyunba.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/zs_imgs/quyunba-bg.png"),"alt":""}})])}]

export { render, staticRenderFns }