<template>
  <div class="zsca-wiki-home">
    <!-- <div class="bg"></div> -->
    <HomeHeader :entryList="entryList" :isScroll="true" />
    <main class="wiki-home-main">
      <div class="left">
        <div class="top-box">
          <!-- banner -->
          <WikiBanner :list="modelsList[1].list" :isEntry="true" />
          <!-- 中间卡池、活动、公告 -->
          <MiddleBox :modelsList="modelsList" @change-server="handleChangeServer" />
        </div>
        <div class="bottom-box">
          <!-- 常用导航 -->
          <CommonNavigation :list="modelsList[20].list" />
          <!-- 普通词条 -->
          <template v-for="item in entryList">
            <home-model-container v-if="item.status == 1" :key="item.id" :item="item" />
          </template>
        </div>
      </div>
      <div class="right">
        <!-- 社区 -->
        <qz-list
          :list="qzList"
          :cdkList="cdkList"
          :serverId="currentServer"
          :serverList="modelsList[19].list"
          :lastUpdateList="lastUpdateList"
          @onQzOrderChange="handleQzOrderChange"
          @onShowMore="handleQzShowMore"
        />
      </div>
      <!-- 侧边栏 -->
      <AsideBar :modelsList="modelsList" />
      <!-- 左侧侧边栏 -->
      <LeftAsideBar :modelsList="modelsList" />
      <!-- 回到顶部 -->
      <ScrollTop />
    </main>
  </div>
</template>

<script>
// components
import MiddleBox from "./components/middleBox.vue";
import HomeModelContainer from "./components/homeModelContainer.vue";
import QzList from "./components/qzList.vue";
import HomeHeader from "./components/homeHeader.vue";
import AsideBar from "./components/asideBar";
import ScrollTop from "./components/scrollTop.vue";
import LeftAsideBar from "./components/leftAsideBar";
import CommonNavigation from "./components/commonNavigation.vue";
// api
import { apiWikiEntry, apiWikiIndexV2 } from "@/api/wikiApi";
import { apiContentTopList } from "@/api/contentApi";

// utils
import { handleModelList } from "../utils";
// mixins
import mergeAsyncDataMixin from "@/mixins/mergeAsyncDataMixin";

export default {
  name: "wikiHome",
  components: {
    /** fix: swiperjs分页器数量与实际banner数量不一致 */
    WikiBanner: () => import("./components/wikiBanner.vue"),
    MiddleBox,
    HomeModelContainer,
    QzList,
    HomeHeader,
    AsideBar,
    ScrollTop,
    LeftAsideBar,
    CommonNavigation
  },
  mixins: [mergeAsyncDataMixin],
  data() {
    return {
      modelsList: handleModelList(),
      isSSRGetData: false,
      pagination: {
        limit: 20,
        page_no: 1,
        page_total: 1,
        total: 0
      },
      activeOrder: "created_at",
      qzList: [],
      entryList: [],
      lastUpdateList: [],
      // 跟middleBox组件的activeServe对应
      currentServer: 1
    };
  },
  computed: {
    cdkList() {
      if (this.modelsList && this.modelsList[21] && this.modelsList[21].list) {
        return this.modelsList[21].list[this.currentServer] || [];
      }
      return [];
    }
  },
  async asyncData({ store, ssrContext, $apis }) {
    try {
      console.log("asyncData start");
      // TDK设置
      const gameInfo = store.getters.gameInfo;
      if (gameInfo.is_tdk && gameInfo.Config) {
        ssrContext.title = gameInfo.Config.seo_title;
        ssrContext.keywords = gameInfo.Config.seo_keywords;
        ssrContext.description = gameInfo.Config.seo_desc;
      } else {
        ssrContext.title = `${gameInfo.name}wiki官网_${gameInfo.name}图鉴|GameKee`;
        ssrContext.keywords = `${gameInfo.name}wiki,${gameInfo.name}官网,${gameInfo.name},${gameInfo.name}人物图鉴,${gameInfo.name}装备图鉴`;
        ssrContext.description = `${gameInfo.name}wiki官网为您带来${gameInfo.name}从新手到高手的一切攻略内容，这是由大神玩家们制作的${gameInfo.name}专属资料库，包含实时更新的${gameInfo.name}人物图鉴和${gameInfo.name}装备图鉴。`;
      }
      // 数据预取
      let pagination = {
        limit: 20,
        page_no: 1,
        page_total: 1,
        total: 0
      };

      const params = {
        type: "4,17",
        order_by: "created_at",
        ...pagination
      };
      const time = new Date().getTime();
      console.log(time);
      const res = await Promise.all([
        $apis.apiWikiIndexV2(),
        $apis.apiContentTopList(params),
        $apis.apiWikiEntry(),
        $apis.apiWikiEntryLastUpdateList()
      ]);

      const { code: wikiResCode, data: wikiData } = res[0];

      let modelsList = handleModelList();
      if (wikiResCode == 0) {
        modelsList = handleModelList(wikiData);
      }
      const { code: qzResCode, data: qzData, meta: qzMeta } = res[1];
      let qzList = [];
      if (qzResCode == 0) {
        qzList = qzData;
        pagination = qzMeta.pagination;
      }
      const { code: entryResCode, data: entryResData } = res[2];
      let entryList = [];
      if (entryResCode == 0) {
        entryList = entryResData.entry_list;
      }

      const { code: lastUpdateResCode, data: lastUpdateResData } = res[3];
      let lastUpdateList = [];
      if (lastUpdateResCode == 0) {
        lastUpdateList = lastUpdateResData;
      }

      console.log("asyncData end");
      return {
        modelsList,
        qzList,
        pagination,
        entryList,
        lastUpdateList,
        isSSRGetData: true
      };
    } catch (err) {
      console.log(err);
    }
  },
  beforeMount() {
    // 判断服务端是否已获取数据
    if (!this.isSSRGetData) {
      this.init();
    }
  },
  methods: {
    init() {
      this.getIndexData();
      this.getEntryData();
      this.getQzList();
    },
    getIndexData() {
      apiWikiIndexV2().then(res => {
        this.modelsList = handleModelList(res.data);
      });
    },
    getEntryData() {
      apiWikiEntry().then(res => {
        this.entryList = res.data.entry_list;
      });
    },
    handleQzOrderChange(value) {
      this.activeOrder = value;
      this.pagination.page_no = 1;
      this.qzList = [];
      this.getQzList();
    },
    handleQzShowMore() {
      this.pagination.page_no += 1;
      if (this.pagination.page_no > this.pagination.page_total) return;
      this.getQzList();
    },
    getQzList() {
      apiContentTopList({
        type: "4,17",
        order_by: this.activeOrder,
        ...this.pagination
      }).then(res => {
        this.qzList = this.qzList.concat(res.data);
        this.pagination = res.meta.pagination;
      });
    },
    // 切换区服
    handleChangeServer(active) {
      this.currentServer = active;
    }
  }
};
</script>

<style lang="less" scoped>
.zsca-wiki-home {
  width: 100%;
  min-height: 100vh;
  padding-top: 65px;

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    // width: 100vw;
    // height: 100vh;
    background: var(--zs-wiki-home-bg-img) no-repeat;
    background-size: 100% 100%;
  }
  .wiki-home-main {
    position: relative;
    width: 1216px;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.25);
    background: var(--zs-wiki-home-main-bg);
    border-radius: 15px;
    padding: 10px;
    display: flex;
    gap: 10px;
    .left {
      width: 817px;
      .top-box {
        display: flex;
        gap: 8px;
        margin-bottom: 12px;
      }
      .bottom-box {
        width: 100%;
        background: var(--zs-wiki-banner-bg);
        padding: 10px;
        border-radius: 8px;
      }
    }
    .right {
      width: 369px;
      background-color: var(--zs-wiki-banner-bg);
      border-radius: 8px;
    }
  }
}
</style>
