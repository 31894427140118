<template>
  <div class="nav-grid">
    <div
      class="model-title"
      :style="
        theme
          ? {
              background: theme.tabBg,
              color: theme.tabTextColor
            }
          : {}
      "
    >
      <i
        class="dot"
        :style="
          theme
            ? {
                backgroundColor: theme.tabDotBg
              }
            : {}
        "
      ></i>
      <span class="title">常用导航</span>
    </div>
    <div class="grid">
      <a v-for="(item, index) in list" :key="index" class="item" :title="item.link" :href="item.link">
        <img class="icon" :src="item.icon" />
        <span class="txt">{{ item.name }}</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavGrid",
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.nav-grid {
  border-radius: 8px;
  background-color: var(--zs-qz-card-bg);

  .model-title {
    display: flex;
    align-items: center;
    height: 34px;
    font-weight: bold;
    font-size: 16px;
    color: var(--zs-server-tab-color);
    line-height: 34px;
    background: var(--zs-server-wrapper-bg);
    margin-bottom: 10px;
    border-radius: 4px;

    .dot {
      display: block;
      width: 3px;
      height: 16px;
      background: var(--zs-model-title-before);
      margin: 0 7px 0 9px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    padding: 12px;
    gap: 12px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      background-color: var(--zs-qz-nav-item-bg);
      border-radius: 6px;
      padding: 10px 0;
      gap: 10px;
      cursor: pointer;

      .icon {
        width: 50px;
        height: 50px;
      }

      .txt {
        color: var(--zs-qz-entry-text-color);
        font-size: 12px;
        font-weight: 600;
      }
    }

    .item:hover {
      transform: scale(1.05);
      animation: scale 0.2s ease-in-out;
    }
  }
}
</style>
