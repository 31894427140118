<template>
  <div class="calendar-activity">
    <!-- 标题 -->
    <router-link :to="`/${alias}/huodong/${serverId}`">
      <div class="logo-title">
        <div class="left">
          <!-- <img class="icon" src="@/assets/zs_imgs/activity-icon.svg" alt="" /> -->
          <i
            class="icon zsicon icon-huodong"
            :style="
              theme
                ? {
                    color: theme.activeTextColor
                  }
                : {}
            "
          ></i>
          活动
        </div>
        <div class="right">
          全部
          <i class="el-icon-arrow-right" style="font-size: 10px;margin-left: 2px;"></i>
        </div>
      </div>
    </router-link>
    <!--  -->
    <div class="list">
      <div class="normal-list" v-if="list.length">
        <div class="line1">
          <div class="scroll-wrapper" ref="scroll">
            <div class="scroll-content">
              <template>
                <a class="card" v-for="(item, index) in list" :key="index" :href="item.link_url" target="_blank">
                  <!-- :to="`/${alias}/calendar-activity/${serverId}?ancher=${item.id}`" -->
                  <div class="banner">
                    <img :src="item.picture" alt="" />
                    <div class="mask"></div>
                  </div>
                  <div class="time-left">
                    <img class="icon" src="@/assets/zs_imgs/time-left-icon.svg" alt="" />{{ formatTime(item.end_at) }}
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <div class="tag" v-if="item.tag">{{ item.tag }}</div>
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import BScroll from "@better-scroll/core";

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    serverId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    normalList() {
      return this.list;
    },
    inportanceList() {
      return [];
    }
  },
  mounted() {
    this.initBS();
  },
  methods: {
    initBS() {
      this.bs = new BScroll(this.$refs.scroll, {
        scrollX: true,
        probeType: 3
      });
    },
    formatTime(time) {
      const now = dayjs().unix();

      if (time - now > 24 * 60 * 60) {
        return Math.floor((time - now) / (24 * 60 * 60)) + "天";
      } else if (time - now > 60 * 60) {
        return Math.floor((time - now) / (60 * 60)) + "小时";
      } else if (time - now > 0) {
        return Math.floor((time - now) / 60) + "分钟";
      } else {
        return "已过期";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.calendar-activity {
  width: 100%;
  margin-bottom: 13px;
  .logo-title {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    color: var(--zs-card-pool-text-color);
    // line-height: 20px;
    margin-bottom: 6px;
    .left {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 5px;
        color: var(--zs-card-pool-icon-color);
        font-size: 24px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      color: var(--zs-card-pool-more-color);
    }
  }
  .list {
    width: 100%;
    padding: 0 0 0 0;
    .scroll-wrapper {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      .scroll-content {
        display: inline-block;
      }
    }
  }

  .importance-list {
    flex-shrink: 0;
  }
  .normal-list {
    flex-shrink: 0;
  }

  .line1 {
    // margin-bottom: 12px;
  }
  .line1,
  .line2 {
    display: flex;
    gap: 8px;
  }

  .card {
    display: inline-block;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;
    width: 168px;
    height: 80px;
    background: #2a323b;
    border-radius: 8px;
    overflow: hidden;
    &.inportance-card {
      width: 150px;
      height: 153px;
    }
    .banner {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: #e3e3e3;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 79px;
        height: 37px;
        background: url("~@/assets/zs_imgs/activity-time-left-bg.png");
        background-size: 100% 100%;
      }
    }
    .time-left {
      display: flex;
      align-items: center;
      position: absolute;
      top: 2px;
      left: 2px;
      height: 16px;
      z-index: 1;
      display: inline-flex;
      padding: 0 8px 0 5px;
      // background: rgba(19, 23, 33, 0.7);
      // border-radius: 8px;
      font-weight: 400;
      font-size: 10px;
      color: #fff;

      .icon {
        width: 11px;
        height: 11px;
        margin-right: 2px;
        background: transparent;
      }
    }
    .tag {
      position: absolute;
      right: 2px;
      top: 2px;
      height: 16px;
      padding: 0 6px;
      background: #d27246;
      border-radius: 8px;
      font-size: 10px;
      color: #d5f9ff;
      line-height: 16px;
    }
    .title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 21px;
      background: rgba(48, 54, 82, 0.73);
      font-weight: 400;
      font-size: 12px;
      color: #d0ccc4;
      line-height: 21px;
      padding: 0 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      text-align: center;
    }
  }
}
</style>
