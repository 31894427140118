<template>
  <div class="middle-box" v-if="modelsList">
    <!-- 区服 -->
    <ServerTabs :list="modelsList[19].list" :active="activeServe" @change="changeServer" />
    <!-- 卡池 -->
    <CardPool :list="cardPoolList" :serverId="activeServe" />
    <!-- 活动 -->
    <CalendarActivity :list="calendarList" :serverId="activeServe" />
    <!-- 公告 -->
    <NoticeList :list="noticeList" :all="true" />
  </div>
</template>

<script>
import ServerTabs from "./serverTabs.vue";
import CardPool from "./cardPool.vue";
import CalendarActivity from "./calendarActivity.vue";
import NoticeList from "./noticeList.vue";

export default {
  props: {
    modelsList: {
      type: Object,
      default: null
    }
  },

  components: {
    ServerTabs,
    CardPool,
    CalendarActivity,
    NoticeList
  },

  data() {
    return {
      activeServe: 1
    };
  },
  computed: {
    serverList() {
      return this.modelsList && this.modelsList[19] ? this.modelsList[19].list : [];
    },
    cardPoolList() {
      if (this.modelsList && this.modelsList[18] && this.modelsList[18].list) {
        return this.modelsList[18].list[this.activeServe] || [];
      }
      return [];
    },
    calendarList() {
      if (this.modelsList && this.modelsList[5] && this.modelsList[5].list) {
        return this.modelsList[5].list[this.activeServe] || [];
      }
      return [];
    },
    noticeList() {
      if (this.modelsList && this.modelsList[6] && this.modelsList[6].list) {
        return this.modelsList[6].list[this.activeServe] || [];
      }
      return [];
    }
  },
  watch: {
    serverList: {
      handler(val) {
        if (val && val.length) {
          this.activeServe = val[0].id;
          this.$emit("change-server", this.activeServe);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeServer(active) {
      this.activeServe = active;
      this.$emit("change-server", active);
    }
  }
};
</script>

<style lang="less" scoped>
.middle-box {
  width: 393px;
  height: 416px;
  background: var(--zs-wiki-banner-bg);
  border-radius: 12px;
  padding: 10px;
}
</style>
