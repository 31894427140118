<template>
  <div class="notice-list">
    <!-- 标题 -->
    <div class="title">
      <div class="left">
        <!-- <img class="icon" src="@/assets/zs_imgs/notice-icon.svg" alt="" /> -->
        <i
          class="icon zsicon icon-yangshengqi"
          :style="
            theme
              ? {
                  color: theme.activeTextColor
                }
              : {}
          "
        ></i>
        公告
      </div>
      <router-link :to="`/${gameInfo.alias}/list?tab=2`" class="right">
        更多
        <i class="el-icon-arrow-right" style="font-size: 10px;margin-left: 2px;"></i>
      </router-link>
    </div>
    <div class="list">
      <template v-for="(item, index) in list.slice(0, 2)">
        <router-link
          v-if="all || (!all && index < 3)"
          :key="index"
          :to="getJumpWikiDetailUrl(gameInfo.alias, gameInfo.id, item.id, true)"
          class="list-item"
        >
          <div class="left">
            <span :class="['notice-tag-color', `notice-tag-color-${tagList[item.tag || 0].color || 'red'}`]">
              [{{ tagList[item.tag || 0].text }}]
            </span>
            <span class="title">{{ item.title }}</span>
          </div>
          <div class="right">
            <span class="type">[公告]</span>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getJumpWikiDetailUrl } from "@/plugins/util";

function getTagList($vm) {
  // console.log($vm);
  return {
    0: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    1: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    2: {
      color: "red",
      text: $vm.$t("notice_tags.tag3")
    },
    3: {
      color: "orange",
      text: $vm.$t("notice_tags.tag2")
    },
    4: {
      color: "green",
      text: $vm.$t("notice_tags.tag1")
    }
  };
}

export default {
  name: "noticeListGroup",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    all: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["gameInfo"]),
    tagList() {
      return getTagList(this);
    }
  },
  methods: {
    getJumpWikiDetailUrl
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.notice-list {
  > .title {
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    color: var(--zs-card-pool-text-color);
    line-height: 20px;
    margin-bottom: 6px;
    .left {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 5px;
        color: var(--zs-card-pool-icon-color);
        font-size: 24px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 13px;
      color: var(--zs-card-pool-more-color);
    }
  }
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }

    .notice-tag-color {
      font-weight: 500;
      font-size: 13px;
      &.notice-tag-color-red {
        background: none;
        color: #ffb116;
        // border: 1px solid #ffb116;
      }

      &.notice-tag-color-orange {
        background: none;
        color: #2e7cfd;
        // border: 1px solid #2e7cfd;
      }

      &.notice-tag-color-green {
        background: none;
        color: #00cb1e;
        // border: 1px solid #00cb1e;
      }
    }

    .title {
      margin-left: 10px;
      font-weight: 400;
      font-size: 13px;
      color: var(--zs-card-pool-notice-color);
      line-height: 13px;
      display: inline-block;
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .type {
      font-weight: 400;
      font-size: 13px;
      color: var(--zs-card-pool-notice-color);
      line-height: 13px;
    }
  }
}
</style>
