var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aside-bar"},[_c('el-popover',{attrs:{"trigger":"hover","placement":"right-start","popper-class":"popover-box","width":"274","visible-arrow":false}},[_c('div',{staticClass:"container",style:(_vm.theme
          ? {
              background: _vm.theme.asidePopBg
            }
          : {})},[_c('div',{staticClass:"title"},[_vm._v("公告")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"gg-content",domProps:{"innerHTML":_vm._s(_vm.gameInfo.description)}})])]),_c('div',{staticClass:"item",attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"icon zsicon icon-gonggao",attrs:{"alt":""}}),_c('span',{staticClass:"label"},[_vm._v("公告")])])]),_c('el-popover',{attrs:{"trigger":"hover","placement":"right-start","popper-class":"popover-box","width":"274","visible-arrow":false}},[_c('div',{staticClass:"container",style:(_vm.theme
          ? {
              background: _vm.theme.asidePopBg
            }
          : {})},[_c('div',{staticClass:"title"},[_vm._v("友情链接")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"friend-links"},_vm._l((_vm.friendLinkList),function(item,index){return _c('a',{key:index,staticClass:"link-item",attrs:{"href":item.link,"target":"_blank"}},[(item.icon)?[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.handleImgSize(item.icon, 'small')),expression:"handleImgSize(item.icon, 'small')"}],attrs:{"alt":""}})]:_vm._e(),_c('span',[_vm._v(_vm._s(item.desc))])],2)}),0)])]),_c('div',{staticClass:"item",attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"icon zsicon icon-QQ",attrs:{"alt":""}}),_c('span',{staticClass:"label"},[_vm._v("友链")])])]),_c('el-popover',{attrs:{"trigger":"hover","placement":"right-start","popper-class":"popover-box","width":"274","visible-arrow":false}},[_c('div',{staticClass:"container",style:(_vm.theme
          ? {
              background: _vm.theme.asidePopBg
            }
          : {})},[_c('div',{staticClass:"title"},[_vm._v("管理员")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"manage-user-list"},_vm._l((_vm.adminList.slice(0, 6)),function(item,index){return _c('router-link',{key:index,staticClass:"user-item",attrs:{"to":("/user/index/" + (item.uid) + ".html")}},[_c('img',{staticClass:"avatar",attrs:{"src":item.avatar,"alt":""}}),_c('span',{staticClass:"username"},[_vm._v(_vm._s(item.username))])])}),1),_c('div',{staticClass:"apply-admin",style:(_vm.theme
              ? {
                  background: _vm.theme.primaryBtnBg,
                  color: _vm.theme.primaryBtnColor
                }
              : {}),on:{"click":_vm.openApply}},[_vm._v(" 申请成为管理员 ")]),_c('apply-wiki-admin',{ref:"applyWikiAdmin"})],1)]),_c('div',{staticClass:"item",attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"icon zsicon icon-guanli",attrs:{"alt":""}}),_c('span',{staticClass:"label"},[_vm._v("管理")])])]),_c('div',{staticClass:"item",on:{"click":_vm.share}},[_c('i',{staticClass:"icon zsicon icon-fenxiang2",staticStyle:{"font-size":"20px"},attrs:{"alt":""}}),_c('span',{staticClass:"label"},[_vm._v("分享")])]),_c('div',{staticClass:"item",on:{"click":_vm.goEdit}},[_c('i',{staticClass:"icon zsicon icon-bianji1",staticStyle:{"font-size":"20px"},attrs:{"alt":""}}),_c('span',{staticClass:"label"},[_vm._v("发帖")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }