<template>
  <div class="card-pool">
    <!-- 标题 -->
    <div class="logo-title">
      <div class="left">
        <!-- <img class="icon" src="@/assets/zs_imgs/card-pool-icon.svg" alt="" /> -->
        <i
          class="icon zsicon icon-kachi"
          :style="
            theme
              ? {
                  color: theme.activeTextColor
                }
              : {}
          "
        ></i>
        卡池
      </div>
      <template v-if="alias === 'zsca2'">
        <router-link :to="`/${alias}/quilt-card/${serverId}`" style="margin-right: auto; margin-left: 10px;">
          <div class="quilt-card">抽卡模拟器</div>
        </router-link>
      </template>
      <router-link :to="`/${alias}/kachi/${serverId}`">
        <div class="right">
          全部
          <i class="el-icon-arrow-right" style="font-size: 10px;margin-left: 2px;"></i>
        </div>
      </router-link>
    </div>
    <!-- 列表 -->
    <div class="list card-pool-list">
      <div class="scroll-wrapper" ref="scroll">
        <div class="scroll-content">
          <a class="card" v-for="(item, index) in list" :key="index" :href="item.link_url" target="_blank">
            <!--             :to="`/${alias}/card-pool/${serverId}?ancher=${item.id}`" -->
            <div class="icon">
              <img :src="item.icon" alt="" />
              <div class="time-left" v-if="formatTime(item.end_at, item.now_at)">
                <img class="icon" src="@/assets/zs_imgs/time-left-icon.svg" alt="" />
                {{ formatTime(item.end_at, item.now_at) }}
              </div>
              <div class="tag" v-if="item.tag">{{ item.tag }}</div>
            </div>
            <div class="name">{{ alias === "zsca2" ? item.name_alias : item.name }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import BScroll from "@better-scroll/core";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    serverId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      bs: null
    };
  },
  mounted() {
    this.initBS();
  },
  methods: {
    initBS() {
      this.bs = new BScroll(this.$refs.scroll, {
        scrollX: true,
        probeType: 3
      });
    },
    formatTime(time, now) {
      now = now || dayjs().unix();
      if (time - now > 5 * 24 * 60 * 60) {
        return "";
      }
      if (time - now > 24 * 60 * 60) {
        return Math.floor((time - now) / (24 * 60 * 60)) + "天";
      } else if (time - now > 60 * 60) {
        return Math.floor((time - now) / (60 * 60)) + "小时";
      } else if (time - now > 0) {
        return Math.floor((time - now) / 60) + "分钟";
      } else {
        return "已过期";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.card-pool {
  width: 100%;
  margin-bottom: 13px;
  .logo-title {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 13px;
    color: var(--zs-card-pool-text-color);
    // line-height: 20px;
    margin-bottom: 6px;
    .left {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 5px;
        color: var(--zs-card-pool-icon-color);
        font-size: 24px;
      }
    }
    .quilt-card {
      height: 20px;
      width: 90px;
      color: #fff;
      background: var(--zs-qz-btn-bg) url("~@/assets/zs_imgs/ck-entry-icon.png") no-repeat;
      border-radius: 20px;
      line-height: 20px;
      background-position: 10px center;
      padding: 0 10px 0 30px;
      text-align: right;
      font-size: 10px;
      background-size: 16px 16px;
    }
    .right {
      display: flex;
      align-items: center;
      color: var(--zs-card-pool-more-color);
    }
  }
  .list {
    width: 100%;
    padding: 0 0 0 0;
    .scroll-wrapper {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      .scroll-content {
        display: inline-block;
      }
    }
  }
  .card {
    display: inline-block;
    width: 73px;
    height: 103px;
    background: var(--zs-card-pool-list-bg);
    border-radius: 8px;
    margin-right: 8px;
    .icon {
      position: relative;
      width: 100%;
      height: 80px;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
        background: #e3e3e3;
      }
      .time-left {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 29px;
        // background: rgba(19, 23, 33, 0.8);
        font-weight: 400;
        font-size: 10px;
        color: #fff;
        padding: 11px 4px 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 42%, rgba(0, 0, 0, 0.72) 100%);
        .icon {
          width: 11px;
          height: 11px;
          margin-right: 2px;
          background: transparent;
        }
      }
      .tag {
        position: absolute;
        right: 4px;
        top: 4px;
        height: 16px;
        padding: 0 6px;
        background: #d27246;
        border-radius: 8px;
        font-size: 10px;
        color: #d5f9ff;
        line-height: 16px;
      }
    }
    .name {
      width: 100%;
      height: 23px;
      padding: 0 4px;
      font-weight: 400;
      font-size: 12px;
      color: var(--zs-card-pool-name-color);
      line-height: 23px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
