<template>
  <div class="qz-list" :style="{ maxHeight: homeLeftHeight + 'px' }">
    <div
      class="title"
      :style="
        theme
          ? {
              backgroundColor: theme.tabBg,
              color: theme.tabTextColor
            }
          : {}
      "
    >
      <div class="left">
        <i
          class="dot"
          :style="
            theme
              ? {
                  backgroundColor: theme.tabDotBg
                }
              : {}
          "
        ></i>
        {{ gameInfo && gameInfo.Config && gameInfo.Config.sq_alias ? gameInfo.Config.sq_alias : "圈子" }}
      </div>
      <div class="right" @click="openMore">更多<i class="el-icon-arrow-right"></i></div>
    </div>
    <div class="btns">
      <button
        class="btn"
        @click="goEdit"
        :style="
          theme
            ? {
                backgroundColor: theme.primaryBtnBg,
                color: theme.primaryBtnColor
              }
            : {}
        "
      >
        <i class="icon zsicon icon-bianji" alt=""></i>
        <span>投稿/发帖</span>
      </button>
      <button
        class="btn"
        @click="openQuestion"
        :style="
          theme
            ? {
                backgroundColor: theme.primaryBtnBg,
                color: theme.primaryBtnColor
              }
            : {}
        "
      >
        <i class="icon zsicon icon-tiwen" alt=""></i>
        <span>萌新提问</span>
      </button>
    </div>
    <div class="list-nav">
      <div
        v-for="(item, index) in orderObj"
        :key="index"
        :class="{ active: activeOrder === item.value }"
        :style="
          theme && activeOrder === item.value
            ? {
                color: theme.activeTextColor
              }
            : {}
        "
        class="list-nav-item"
        @click="e => handleOrder(e, item.value)"
      >
        {{ item.label }}
      </div>
      <div
        class="active-bar"
        :style="
          theme
            ? { left: activeBarOffset + 'px', backgroundColor: theme.activeTextColor }
            : { left: activeBarOffset + 'px' }
        "
      ></div>
    </div>
    <div class="list" :style="{ maxHeight: homeLeftHeight - 220 + 'px', overflow: 'hidden' }">
      <div v-for="(item, index) in list" :key="index" style="margin-bottom: 18px;">
        <router-link class="list-item" :to="getJumpWikiDetailUrl(item.game.alias, item.game.id, item.id, true)">
          <div class="top">
            <div class="left">
              <span class="is-top" v-if="item.is_top">[置顶]</span>
              <span>{{ item.title }}</span>
            </div>
            <div class="right">
              <img
                class="icon has-img"
                v-if="item.image_num || item.thumb"
                src="@/assets/zs_imgs/has-img-icon.png"
                alt=""
              />
              <img
                class="icon has-video"
                v-if="item.video_list_format && item.video_list_format.length"
                src="@/assets/zs_imgs/has-video-icon.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div class="middle summary">
            {{ item.summary }}
          </div> -->
          <div class="bottom">
            <div class="user">
              <img class="avatar" :src="item.user.avatar" alt="" />
              <span class="username">{{ item.user.username }}</span>
            </div>
            <div class="data">
              <div class="data-item comment-data">
                <img class="icon" src="@/assets/zs_imgs/wiki-list-comment-icon.png" alt="" />
                {{ item.comment_count }}
              </div>
              <div class="data-item like-data" @click.stop.prevent="onLike(item, index)">
                <img
                  class="icon"
                  :src="
                    item.is_like
                      ? require('@/assets/zs_imgs/wiki-list-like-active-icon.png')
                      : require('@/assets/zs_imgs/wiki-list-like-icon.png')
                  "
                  alt=""
                />
                {{ item.like_count }}
              </div>
            </div>
          </div>
        </router-link>
        <!-- 去云吧 -->
        <div class="quyunba-box" v-if="index == 1">
          <a href="https://www.quyunba.com/" target="_blank">
            <img src="@/assets/zs_imgs/quyunba-bg.png" alt="" />
          </a>
        </div>
        <!-- 词条更新 -->
        <EntryUpdate v-if="index == 3" :list="lastUpdateList"></EntryUpdate>

        <!-- 阵容模拟器banner -->
        <div v-if="alias === 'zsca2' && index === 5" class="battle-banner" @click="goEdit('battle')"></div>

        <div v-if="alias === 'zsca2' && serverId && index === 7" class="ck-banner" @click="goCk"></div>

        <!-- 兑换码 -->
        <CdkBanner
          v-if="index === 9 && cdkList && cdkList.length"
          :list="cdkList"
          :serverId="serverId"
          :serverList="serverList"
        ></CdkBanner>
      </div>
    </div>
    <div class="more" ref="moreBtn" @click="openMore">
      查看更多
    </div>
    <!-- 提问 -->
    <QuestionAdd ref="questionAdd" :customClass="questionAddCustomClass"></QuestionAdd>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getJumpWikiDetailUrl, throttle } from "@/plugins/util";
import QuestionAdd from "../../components/questionAdd";
import EntryUpdate from "./entryUpdate";
import CdkBanner from "./cdkBanner.vue";
import { getCookies } from "@/plugins/cookies";

// api
import { apiContentLike } from "@/api/contentApi";

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    lastUpdateList: {
      type: Array,
      default: () => []
    },
    cdkList: {
      type: Array,
      default: () => []
    },
    serverId: {
      type: Number,
      default: 0
    },
    serverList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    QuestionAdd,
    EntryUpdate,
    CdkBanner
  },
  data() {
    return {
      questionAddCustomClass: "",
      activeOrder: "created_at",
      orderObj: [
        {
          label: "最新发帖",
          value: "created_at"
        },
        {
          label: "最新回复",
          value: "comment_at"
        },
        {
          label: "热门文章",
          value: "hot"
        }
      ],
      activeBarOffset: 46,
      homeLeftHeight: 1000
    };
  },
  computed: {
    ...mapGetters(["gameInfo"])
  },
  mounted() {
    window.addEventListener("scroll", this.checkMoreBtnIsIn);
    setTimeout(() => {
      const homeLeftEl = document.querySelector(".wiki-home-main .left");
      if (homeLeftEl) {
        this.homeLeftHeight = homeLeftEl.offsetHeight;
      }
    }, 2000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkMoreBtnIsIn);
  },
  methods: {
    getJumpWikiDetailUrl,
    handleOrder(e, value) {
      this.activeOrder = value;
      const el = e.target;
      this.activeBarOffset = el.offsetLeft + 7;
      this.$emit("onQzOrderChange", value);
    },
    checkMoreBtnIsIn: throttle(function() {
      const moreBtnEl = this.$refs.moreBtn;
      const moreBtnPosition = moreBtnEl.getBoundingClientRect();
      this.homeLeftHeight = document.querySelector(".wiki-home-main .left")?.offsetHeight;
      const listHeight = document.querySelector(".qz-list")?.offsetHeight;
      // console.log(this.homeLeftHeight, listHeight);
      if (this.homeLeftHeight && this.homeLeftHeight != listHeight && moreBtnPosition.top - window.innerHeight <= 100) {
        this.$emit("onShowMore");
      }
    }, 500),
    openMore() {
      this.$router.push(`/${this.alias}/qz`);
    },
    openQuestion() {
      const theme = this.alias === "zsca2" ? getCookies("wikiTheme") : "";
      this.questionAddCustomClass = theme;
      this.$refs.questionAdd.open();
    },
    goCk() {
      this.$router.push(`/${this.gameInfo.alias}/quilt-card/${this.serverId}`);
    },
    goEdit(type) {
      if (!this.isLoginShow("")) {
        sessionStorage.setItem("login_jump_path", `/${this.gameInfo.alias}/editor`);
        return false;
      }
      if (!this.isAuthRealName()) {
        sessionStorage.setItem("login_jump_path", `/${this.gameInfo.alias}/editor`);
        return false;
      }
      let url = `/${this.gameInfo.alias}/editor`;
      if (type === "battle") {
        url += "?autoSetBattle=1";
      }
      this.$router.push(url);
    },
    onLike(item, index) {
      let params = {
        is_like: item.is_like ? 0 : 1,
        origin_id: item.id,
        type: 1
      };
      apiContentLike(params).then(res => {
        if (res.code === 0) {
          this.list[index].is_like = params.is_like;
          params.is_like ? this.list[index].like_count++ : this.list[index].like_count--;
          if (this.list[index].like_count < 0) this.list[index].like_count = 0;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.qz-list {
  position: relative;
  width: 100%;
  padding: 10px 0;
  overflow: hidden;
  padding-bottom: 60px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    height: 34px;
    margin: 0 10px;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: var(--zs-server-wrapper-bg);
    border-radius: 4px;

    .left {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      color: var(--zs-server-tab-color);
      line-height: 24px;
      .dot {
        display: block;
        width: 3px;
        height: 16px;
        background: var(--zs-qz-tag-bg);
        margin-right: 6px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: var(--zs-qz-tag-icon-color);
      line-height: 16px;
      float: right;
      cursor: pointer;
      i {
        font-size: 8px;
        margin-left: 4px;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 14px 19px 13px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 158px;
      height: 32px;
      background: var(--zs-qz-btn-bg);
      border-radius: 16px;
      font-size: 14px;
      color: #ffffff;
      border: none;
      outline: none;
      .icon {
        font-size: 20px;
        margin-right: 3px;
      }
    }
  }
}

.list-nav {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 11px;
  gap: 62px;
  border-bottom: 1px solid var(--zs-qz-nav-border-color);
  margin-bottom: 11px;
  .list-nav-item {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 21px;
    cursor: pointer;
    &.active {
      font-weight: bold;
      color: var(--zs-qz-tab-active-color);
    }
  }

  .active-bar {
    position: absolute;
    top: 41px;
    left: 46px;
    width: 40px;
    height: 3px;
    background: var(--zs-qz-tab-active-color);
    border-radius: 2px;
    transition: all 0.3s;
  }
}

.list {
  padding: 0 10px;
  overflow: hidden;
  .list-item {
    display: block;
    padding: 4px 0 13px;
    border-bottom: 1px solid var(--zs-qz-nav-item-border-color);

    &:last-child {
      margin-bottom: 0;
    }
    .top {
      display: flex;
      margin-bottom: 14px;
      .left {
        flex: 1;
        width: 100%;
        font-weight: 400;
        font-size: 13px;
        color: var(--zs-qz-nav-item-text-color);
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        .is-top {
          color: #0096ff;
          margin-right: 4px;
        }
      }
      .right {
        flex-shrink: 0;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }
    .middle {
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 15px;
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user {
        display: flex;
        align-items: center;
        font-size: 11px;
        color: #999;
        line-height: 16px;
        .avatar {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
      .data {
        display: flex;
        align-items: center;
        gap: 24px;
        .data-item {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 11px;
          color: #999999;
          .icon {
            width: 14px;
            height: 14px;
            margin-right: 2px;
          }
        }
      }
    }
  }

  .quyunba-box {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .battle-banner {
    width: 100%;
    height: 60px;
    background: url("~@/assets/zs_imgs/battle/home_banner.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .ck-banner {
    width: 100%;
    height: 60px;
    background: url("~@/assets/zs_imgs/ck-entry-banner.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
}

.more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}
</style>
