<template>
  <div class="cdk-container">
    <div class="title">兑换码（{{ serverName }}）</div>
    <div class="swiper-container-box">
      <swiper ref="mySwiper" :auto-destroy="true" :auto-update="true" :options="swiperOptions" class="swiper-box">
        <swiper-slide ref="swiperSlide" v-for="(item, index) in calcCdkList" :key="index" class="banner-item">
          <div v-for="obj in item" :key="obj.id" class="flex-line">
            <span class="label">
              {{ obj.created_at ? $dayjs(obj.created_at * 1000).format("M.D") : "" }}
            </span>
            <span class="label" style="margin-left: 2px">更新</span>
            <div class="code-box">
              <span class="limit">{{ obj.end_at ? `${$dayjs.unix(obj.end_at).format("M.D到期")}` : "限量" }}</span>
              <div class="content">
                <div class="val" :title="obj.content">{{ obj.content }}</div>
                <div
                  class="btn"
                  :style="
                    theme
                      ? {
                          backgroundColor: theme.highlightBtnBg,
                          color: theme.highlightBtnColor
                        }
                      : {}
                  "
                  @click="handleCopy(obj.code)"
                >
                  复制兑换码
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div :class="['swiper-pagination']"></div>
    </div>
  </div>
</template>
<script>
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";
import { copyText } from "@/plugins/util";

export default {
  name: "CdkBanner",
  components: { Swiper, SwiperSlide },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    serverId: {
      type: Number,
      default: 0
    },
    serverList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const _this = this;
    return {
      swiperOptions: {
        loop: false,
        autoplay: false,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 8,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet(index, className) {
            return _this.getPaginationHtml(index, className);
          }
        }
      }
    };
  },
  computed: {
    calcCdkList() {
      // 将list按3个一组分组，用于swiper展示
      const cdkList = [];
      for (let i = 0; i < this.list.length; i += 2) {
        cdkList.push(this.list.slice(i, i + 2));
      }
      return cdkList;
    },
    serverName() {
      const server = this.serverList.find(item => item.id === this.serverId);
      return server ? server.name : "";
    }
  },
  methods: {
    getPaginationHtml(index, className) {
      return `<span class="${className}" style="${
        this.theme && this.theme.highlightBtnBg ? `background-color: ${this.theme.highlightBtnBg}` : ""
      }"></span>`;
    },
    handleCopy(code) {
      copyText(code)
        .then(() => {
          this.$message.success("复制成功");
        })
        .catch(() => {
          this.$message.error("复制失败");
        });
    }
  }
};
</script>
<style lang="less" scoped>
.cdk-container {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
  overflow: hidden;
  .title {
    font-weight: bold;
    font-size: 13px;
    color: var(--zs-qz-entry-title-color);
    line-height: 17px;
    margin-bottom: 12px;
  }
  .swiper-container-box {
    position: relative;
    overflow: visible;
    padding-bottom: 18px;
    .banner-item {
      .flex-line {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        .label {
          flex: 0 0 auto;
          color: #999;
          font-size: 12px;
        }
        .code-box {
          flex: 1;
          display: flex;
          align-items: center;
          height: 28px;
          margin-left: 5px;
          border-radius: 4px;
          background: var(--zs-qz-entry-bg-color);
          padding: 2px 4px 2px 10px;
          .limit {
            font-size: 12px;
            color: #666666;
            width: 56px;
          }
          .content {
            flex-grow: 1;
            width: 0;
            background-color: var(--zs-qz-card-bg);
            display: flex;
            align-items: center;
            height: 22px;
            border-radius: 2px;
            padding-right: 2px;
            .val {
              flex: 1;
              padding: 0 6px;
              color: var(--zs-qz-btn-bg);
              font-size: 13px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .btn {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0 0 auto;
              width: 76px;
              height: 18px;
              line-height: 18px;
              border-radius: 4px;
              color: #ffffff;
              font-size: 12px;
              background: var(--zs-qz-btn-bg);
              cursor: pointer;
            }
          }
        }
      }
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      /deep/.swiper-pagination-bullet {
        width: 15px;
        height: 4px;
        margin-right: 6px;
        border-radius: 2px;
        background: var(--zs-qz-btn-bg);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
