var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"model-container",attrs:{"id":("menu-" + (_vm.item.id))}},[_c('div',{staticClass:"model-title",style:(_vm.theme
        ? {
            background: _vm.theme.tabBg,
            color: _vm.theme.tabTextColor
          }
        : {})},[_c('i',{staticClass:"dot",style:(_vm.theme
          ? {
              backgroundColor: _vm.theme.tabDotBg
            }
          : {})}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.item.name))])]),_vm._l((_vm.list),function(child,index){return _c('model-tab',{key:index,attrs:{"list":child}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }