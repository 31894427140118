<template>
  <div class="left-aside-bar">
    <!-- 管理 -->
    <div class="item" v-if="$hasPermission('manage')" @click="handleManage">
      <!-- <img class="icon" src="@/assets/zs_imgs/wiki-icon-manage.png" alt="" /> -->
      <i class="icon zsicon icon-shezhi" alt=""></i>
      <span class="label">管理</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// components

export default {
  props: {
    modelsList: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["gameInfo", "loginUser", "adminList"])
  },
  beforeMount() {},
  methods: {
    handleManage() {
      if (this.$store.state.roleInfo.includes("wiki_detail_edit")) {
        this.$router.push(`/${this.gameInfo.alias}/admin`);
      } else {
        this.$router.push(`/${this.gameInfo.alias}/admin/module`);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.left-aside-bar {
  position: fixed;
  top: 72px;
  right: 50%;
  margin-right: 615px;
  width: 52px;
  .item {
    width: 52px;
    height: 52px;
    color: var(--zs-aside-bar-text-color);
    background: var(--zs-aside-bar-bg);
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 14px;
    .icon {
      font-size: 24px;
    }
    .label {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
    }
  }
}
</style>
