<template>
  <div class="scroll-top" :style="{ bottom: bottom + 'px' }">
    <!-- <img class="icon" src="@/assets/zs_imgs/scrolltop-icon.png" alt="" /> -->
    <div v-if="alias === 'zsca2'" class="item" title="切换主题" @click="handleChangeTheme">
      <i :class="['icon', 'zsicon', themeName === 'light' ? 'icon-baitian' : 'icon-yueliangxingxing']" alt=""></i>
      <!-- <i class="icon zsicon icon-yueliangxingxing" alt=""></i> -->
    </div>
    <div v-if="hasScroll" class="item" title="回到顶部" @click="handleScrollTop">
      <i class="icon zsicon icon-fanhuidingbu" alt=""></i>
    </div>
  </div>
</template>

<script>
import { setCookies } from "@/plugins/cookies";
export default {
  data() {
    return {
      hasScroll: false,
      bottom: 20,
      themeData: ["light", "dark"]
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    handleScroll() {
      const winScrollTop = window.document.documentElement.scrollTop || window.document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      if (winScrollTop > 0) {
        this.hasScroll = true;
      } else {
        this.hasScroll = false;
      }

      if (winScrollTop >= scrollHeight - windowHeight - 156) {
        // console.log("滚动到底部", scrollHeight - windowHeight - scrollTop);
        this.bottom = 156 - (scrollHeight - (winScrollTop + windowHeight));
      }
    },
    handleChangeTheme() {
      const current = this.themeName === "light" ? "dark" : "light";
      setCookies("wikiTheme", current, { expires: 90 });
      this.$store.commit("set_themeName", current);
      document.documentElement.setAttribute("theme", current);
    }
  }
};
</script>

<style lang="less" scoped>
.scroll-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 615px;
  .item {
    width: 40px;
    height: 40px;
    color: var(--zs-aside-bar-text-color);
    background: var(--zs-aside-bar-bg);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    transition: all 0.3s;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .icon {
    font-size: 22px;
    transition: all 0.3s;
  }
}
</style>
