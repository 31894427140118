<template>
  <div class="server-select">
    <div
      class="wrapper"
      :style="
        theme
          ? {
              backgroundColor: theme.tabBg,
              color: theme.tabTextColor
            }
          : {}
      "
    >
      <div class="left">
        <span class="dot"></span>
        <!-- 当前日期 -->
        <div class="now-date">{{ dayjs().format("YYYY年MM月DD日") }} {{ getWeek() }}</div>
      </div>
      <div class="right" v-if="list.length && list.length > 0">
        <el-dropdown trigger="click" @command="changeServer" placement="bottom-start">
          <div
            class="server-select-box"
            :style="
              theme
                ? {
                    backgroundColor: theme.tabSelectBg,
                    color: theme.tabSelectTextColor
                  }
                : {}
            "
          >
            <span>{{ showName }} </span>
            <i class="icon el-icon-caret-right" :class="[list.length <= 1 ? 'disabled-icon' : '']"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="server-select-menu" v-show="list.length > 1">
            <el-dropdown-item
              v-for="item in list"
              :key="item.id"
              :command="item.id"
              :style="
                theme
                  ? {
                      backgroundColor: `${theme.tabSelectBg} !important`,
                      color: theme.tabSelectTextColor
                    }
                  : {}
              "
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    active: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  computed: {
    showName() {
      const server = this.list.find(item => item.id === this.active);
      return server ? server.name : "";
    }
  },
  mounted() {
    console.log("区服列表", this.list);
  },
  methods: {
    dayjs,
    changeServer(active) {
      this.$emit("change", active);
    },
    getWeek() {
      var datas = dayjs().day();
      var week = ["日", "一", "二", "三", "四", "五", "六"];
      return "周" + week[datas];
    }
  }
};
</script>

<style lang="less" scoped>
.server-select-menu {
  background: none !important;
  border-radius: 0 !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
  /deep/ .el-dropdown-menu__item {
    font-size: 13px;
    font-weight: 400;
    color: var(--zs-server-select-color);
    background: var(--zs-server-select-bg) !important;
    height: 23px;
    min-width: 72px;
    border-radius: 11px;
    margin-bottom: 4px;
    line-height: 23px;
    padding: 0 12px;
    font-size: 11px;
    border: 1px solid var(--zs-server-dropdown-border-color);
    &:focus {
      background-color: #292a30 !important;
    }
  }
  /deep/ .popper__arrow {
    display: none !important;
  }
}
.server-select {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 8px;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 34px;
    padding: 3px 5px;
    background-color: var(--zs-server-wrapper-bg);
    border-radius: 4px;
    .left {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 13px;
      color: var(--zs-server-tab-color);
      line-height: 20px;

      .dot {
        width: 3px;
        height: 3px;
        background: var(--zs-server-tab-dot-bg);
        margin-right: 7px;
        border-radius: 50%;
      }
    }
  }

  .server-select-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--zs-server-select-bg);
    border-radius: 4px;
    padding: 0 11px 0 14px;
    min-width: 79px;
    height: 26px;
    line-height: 26px;
    font-weight: 400;
    font-size: 11px;
    color: var(--zs-server-select-color);
    .icon {
      margin-left: 4px;
      color: var(--zs-server-select-color);
      // &.disabled {
      //   color: #2d3339;
      // }
    }
  }
}
</style>
