<template>
  <div class="entry-update">
    <div class="title">词条更新</div>
    <div class="list">
      <template v-for="(item, index) in list">
        <router-link
          :to="getJumpWikiDetailUrl(gameInfo.alias, gameInfo.id, item.id, true, item.editor_type == 3)"
          class="item"
          v-if="index < 6"
          :key="index"
          :style="theme ? { backgroundColor: theme.entryBg, color: theme.entryColor } : {}"
        >
          <div class="item-title">{{ item.title }}</div>
          <div
            class="date"
            :style="
              theme
                ? {
                    color: theme.entryDesColor
                  }
                : {}
            "
          >
            {{ dayjs(item.updated_at * 1000).format("MM.DD") }}
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getJumpWikiDetailUrl } from "@/plugins/util";
import { mapGetters } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["gameInfo"])
  },
  methods: {
    dayjs,
    getJumpWikiDetailUrl
  }
};
</script>

<style lang="less" scoped>
.entry-update {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
  .title {
    font-weight: bold;
    font-size: 13px;
    color: var(--zs-qz-entry-title-color);
    line-height: 17px;
    margin-bottom: 12px;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 10px;
    .item {
      display: block;
      width: 169px;
      height: 28px;
      background: var(--zs-qz-entry-bg-color);
      border-radius: 2px;
      font-size: 12px;
      color: var(--zs-qz-entry-text-color);
      line-height: 16px;
      display: flex;
      align-items: center;
      padding: 0 5px;
      gap: 12px;
      .item-title {
        flex: 1;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 3px;
          height: 3px;
          background: #555469;
          margin-right: 8px;
          margin-left: 5px;
        }
      }
      .date {
        flex-shrink: 0;
        color: var(--zs-qz-entry-date-color);
      }
    }
  }
}
</style>
